@import '../../../styles/customMediaQueries.css';

.root {
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.avatar {
  flex-shrink: 0;
  margin-inline-end: 16px;
}

.info {
  flex: 1;
}

.heading {
  composes: p from global;
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.link {
  white-space: nowrap;
}

.mobileBio,
.desktopBio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  transition: all 1s ease-in;
  margin-block-start: 18px;
  margin-inline-end: 0;
  margin-block-end: 0;
  margin-inline-start: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.mobileBio {
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopBio {
  display: none;

  @media (--viewportMedium) {
    display: inline;

    &::before {
      content: ' ';
    }
  }
}

.longWord {
  hyphens: auto;
}

.showMore {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;

  margin-block-start: 0;
  margin-inline-end: 0;
  margin-block-end: 0;
  margin-inline-start: 5px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-block-start + n * line-height + margin-block-end => x * 8px */
    margin-block-start: 17px;
    margin-block-end: 15px;
  }
}

.links {
  margin-block-start: 13px;

  @media (--viewportMedium) {
    margin-block-start: 16px;
    margin-inline-end: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
  }
}

.withBioMissingAbove {
  @media (--viewportMedium) {
    /* Fix baseline alignment if bio is missing from above */
    margin-block-start: 16px;
  }
}

.linkSeparator {
  margin: 0 10px;
  color: var(--marketplaceColor);
}

.headingRow {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

.editProfileDesktop {
  composes: p from global;
  display: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;

  @media (--viewportMedium) {
    display: inline;
  }
}

.editProfileMobile {
  composes: p from global;
  display: inline;

  @media (--viewportMedium) {
    display: none;
  }
}

.contact {
  composes: a from global;
  font-weight: var(--fontWeightMedium);
  background: #2d6479;
  color: white;
  padding: 0.75rem 2rem;
  border-radius: 9999px;
  font-size: 1.125rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  margin: 3rem auto;
}
