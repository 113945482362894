@import '../../../styles/customMediaQueries.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-block-end: 36px;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
}

.field {
  margin-block-start: 25px;
  margin-inline-end: 0;
  margin-block-end: 24px;
  margin-inline-start: 0;

  @media (--viewportMedium) {
    margin-block-start: 22px;
    margin-inline-end: 0;
    margin-block-end: 24px;
    margin-inline-start: 0;
  }
}

.error {
  color: var(--colorFail);
  margin-block-start: 0;

  @media (--viewportMedium) {
    margin-block-start: 0;
  }
}
